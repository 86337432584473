<template>
  <b-card
    v-if="loadingComplete"
    class="card card-custom gutter-b"
    :title="pageSectionInfo.title"
  >
    <!--begin::Breadcrumb-->
    <ul
      class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
    >
      <template v-for="(breadcrumb, i) in breadcrumbs">
        <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
          <router-link
            :key="`${i}-${breadcrumb.id}`"
            v-if="breadcrumb.route"
            :to="breadcrumb.route"
            class="text-muted"
          >
            {{ breadcrumb.title }}
          </router-link>
          <span
            class="text-muted"
            :key="`${i}-${breadcrumb.id}`"
            v-if="!breadcrumb.route"
          >
            {{ breadcrumb.title }}
          </span>
        </li>
      </template>
    </ul>
    <!--end::Breadcrumb-->

    <p v-html="pageSectionInfo.description"></p>
    <!--begin::Media File-->
    <b-card-text
      v-if="pageSectionInfo.project_assets"
      class="d-flex justify-content-center"
    >
      <InputGenericThumbGallery :projectAssets="pageSectionInfo.project_assets">
      </InputGenericThumbGallery>
    </b-card-text>
    <!--end::Media File-->
  </b-card>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_PAGE_SECTION_INFO } from "@/core/services/store/page/page.module";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";
export default {
  name: "view_page_section",

  components: {
    InputGenericThumbGallery
  },

  directives: {},

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.fetchPageSectionInfo();
  },
  watch: {
    pageId: function() {
      this.fetchPageSectionInfo();
    },
    pageSectionId: function() {
      this.fetchPageSectionInfo();
    },
    pageTitle: {
      handler(val) {
        document.title = (val !== undefined ? val : " ••• ") + " | Input | Together";
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    fetchPageSectionInfo() {
      let payload = {
        pageId: this.pageId,
        pageSectionId: this.pageSectionId,
        params: {
          page: "with",
          projectAssets: "with",
          assignPageSectionAssets: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PAGE_SECTION_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.setBreadcrumb();
        })
        .catch(() => {
          this.error.active = true;
          this.$router.push({ path: "/" });
          this.$toasted.global.error_toast({
            message: this.pageSectionErrors
          });
        });
    },
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.pageSectionInfo.page.title,
          route: "/page/" + this.pageId
        },
        { title: this.pageSectionInfo.title }
      ]);
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "pageInfo",
      "breadcrumbs",
      "pageSectionErrors",
      "pageSectionInfo"
    ]),

    pageId: function() {
      return this.$route.params.id;
    },
    pageSectionId: function() {
      return this.$route.params.pageSectionId;
    },
    pageTitle: function() {
      return this.pageInfo.title + " • " + this.pageSectionInfo.title;
    },

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
